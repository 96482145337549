import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

export default function Login({ setToken }) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);

  const handleSubmit = (e) => {
    setMessage("");
    setLoading(true);
    e.preventDefault();
    const loginData = {
      username: username,
      password: password,
    };

    axios
      // .post(process.env.REACT_APP_API_BASE_URL + "/wp-json/jwt-auth/v1/token", loginData) // JWT Auth & JWT Authentication for WP-API
      .post(process.env.REACT_APP_API_BASE_URL + "/wp-json/api/v1/token", loginData) // miniOrange JWT Authentication
      .then((response) => {
        setToken(response.data.jwt_token); // miniOrange JWT Authentication
        // setToken(response.data.data.token); // JWT Auth
        // setToken(response.data.token); // JWT Authentication for WP-API
      })
      .catch((error) => {
        // setUserName();
        // setPassword();
        setLoading(false);
        setMessage(error.message);
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
        }, 2000);
      });
  };

  return (
    <Modal centered show>
      <Form onSubmit={handleSubmit}>
        <Modal.Header className="bg-info text-white pb-4">
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert show={alertVisible} variant="danger" dismissible>
            <Alert.Heading>Login failed</Alert.Heading>
            {message}
          </Alert>

          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control autoFocus onChange={(e) => setUserName(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light">
          {isLoading ? (
            <Button size="lg" variant="info">
              <Spinner size="sm" />
              Loading...
            </Button>
          ) : (
            <Button size="lg" variant="info" type="submit">
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
