import { useEffect } from "react";
import { useBooleanState, usePrevious } from "webrix/hooks";
import { disableBodyScroll } from "body-scroll-lock";
import Container from "react-bootstrap/Container";

// https://dev.to/alexgurr/turning-a-react-app-into-a-pwa-with-offline-detection-service-workers-and-theming-142i

export default function Offline({ children }) {
  const { value: online, setFalse: setOffline, setTrue: setOnline } = useBooleanState(navigator.onLine);
  const previousOnline = usePrevious(online);

  useEffect(() => {
    if (!online) {
      return void disableBodyScroll(document.body);
    }
  }, [online]);

  useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);

  return (
    <>
      {!online && (
        <Container fluid className="text-center bg-warning text-white h2 p-2 m-0">
          Offline
        </Container>
      )}
      {children}
    </>
  );
}
