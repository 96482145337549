import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Login from "./components/Login";
import Main from "./components/Main";
import NotFound from "./components/NotFound";
import useToken from "./useToken";

function App() {
  const { token, setToken, destroyToken } = useToken();

  if (!token) {
    return <Login setToken={setToken} />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main token={token} destroyToken={destroyToken} />} />
        <Route path="/login" element={<Login setToken={setToken} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
