import React, { useState, useEffect } from "react";
import MileageInput from "../MileageInput";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import moment from "moment";

function InputFieldWrapper({ children }) {
  return (
    <Row>
      {/* <Col sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
        {children}
      </Col> */}
      <Col>{children}</Col>
    </Row>
  );
}

export default function Main({ token, destroyToken }) {
  const [specificData, setSpecificData] = useState({});
  const [isInsert, setIsInsert] = useState(true);
  const [id, setId] = useState(0);
  const [insertDate, setInsertDate] = useState("");
  const [motorbike, setMotorbike] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [place, setPlace] = useState("");
  const [price, setPrice] = useState(0);
  const [type, setType] = useState("");
  const [volume, setVolume] = useState(0);
  const [mileage, setMileage] = useState(0);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [placeOptions, setPlaceOptions] = useState([]);
  const [fuelCapacity, setFuelCapacity] = useState(0);
  const [fuelConsumption, setFuelConsumption] = useState(0);
  const [previousFuelConsumption, setPreviousFuelConsumption] = useState(0);
  const [startMileage, setStartMileage] = useState(0);
  const [mileageSincePurchase, setMileageSincePurchase] = useState(0);
  const [mileageDifference, setMileageDifference] = useState(0);
  const [averagePrice, setAveragePrice] = useState(0);
  const [averageFuelConsumption, setAverageFuelConsumption] = useState(0);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => loadData(), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => loadData(motorbike), [motorbike]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (specificData.length > 0) {
      setMileageDifference(mileage - specificData[0].acf.mileage);
    }
  }, [mileage, specificData]);

  useEffect(() => {
    if (specificData.length > 0) {
      let totalPrice = 0;
      let totalVolume = 0;
      specificData.forEach(function (item) {
        totalPrice += Number(item.acf.price);
        totalVolume += Number(item.acf.volume);
      });
      setAveragePrice((totalPrice / totalVolume).toFixed(3));
      setMileageSincePurchase(specificData[0].acf.mileage - startMileage);
      setAverageFuelConsumption(((specificData[0].acf.mileage - startMileage) / totalVolume).toFixed(3));
    }
  }, [startMileage, specificData]);

  useEffect(() => {
    if (mileageDifference !== 0) {
      setFuelConsumption((mileageDifference / volume).toFixed(3));
    } else {
      setFuelConsumption(previousFuelConsumption);
    }
  }, [mileageDifference, volume, previousFuelConsumption]);

  useEffect(() => {
    if (specificData.length > 0) {
      setId(specificData[0].id);
      setInsertDate(specificData[0].date);
      setCompany(specificData[0].acf.company);
      setCountry(specificData[0].acf.country);
      setPlace(specificData[0].acf.place);
      setPrice(Number(specificData[0].acf.price).toFixed(2));
      setType(specificData[0].acf.type);
      setVolume(Number(specificData[0].acf.volume).toFixed(2));
      setMileage(Number(specificData[0].acf.mileage));
      setFuelConsumption(((specificData[0].acf.mileage - specificData[1].acf.mileage) / specificData[0].acf.volume).toFixed(3));
      setPreviousFuelConsumption(((specificData[0].acf.mileage - specificData[1].acf.mileage) / specificData[0].acf.volume).toFixed(3));
      if (motorbike === "Z400") {
        setFuelCapacity(14);
        setStartMileage(16710);
      }
      if (motorbike === "Versys 650") {
        setFuelCapacity(21);
        setStartMileage(23514);
      }
    }
  }, [specificData]);

  const loadData = (motorbikeInput = "") => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + "/wp-json/wp/v2/fuel-costs?per_page=1000", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (motorbikeInput === "") {
          setMotorbike(response.data[0].acf.motorbike);
          setSpecificData(response.data.filter((item) => item.acf.motorbike === motorbike));
        } else {
          setSpecificData(response.data.filter((item) => item.acf.motorbike === motorbike));
        }
        let uniqueCompanies = [...new Set(response.data.map((item) => item.acf.company))].sort();
        setCompanyOptions(uniqueCompanies);
        let uniqueCountries = [...new Set(response.data.map((item) => item.acf.country))].sort();
        setCountryOptions(uniqueCountries);
        let uniquePlaces = [...new Set(response.data.map((item) => item.acf.place))].sort();
        setPlaceOptions(uniquePlaces);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.message === "Expired token") {
          destroyToken();
        } else {
          console.log(error);
        }
      });
  };

  const increase = (e, index) => {
    e.preventDefault();
    setMileage((prev) => {
      let number = Number(prev);
      number += 10 ** (4 - index);
      return number;
    });
  };

  const decrease = (e, index) => {
    e.preventDefault();
    setMileage((prev) => {
      let number = Number(prev);
      number -= 10 ** (4 - index);
      return number;
    });
  };

  const save = () => {
    setVolume(Number(volume).toFixed(2));
    setPrice(Number(price).toFixed(2));
    let postData = {
      title: moment().format("DD/MM/YYYY") + " - " + company + " - " + place + " - " + volume + " L - &euro;" + price + " - " + motorbike + " - " + mileage + " km",
      status: "publish",
      fields: {
        date: moment().format("YYYYMMDD"),
        type: type,
        motorbike: motorbike,
        company: company,
        country: country,
        place: place,
        volume: volume,
        price: price,
        mileage: mileage,
      },
    };

    if (isInsert) {
      axios
        .post(process.env.REACT_APP_API_BASE_URL + "/wp-json/wp/v2/fuel-costs", postData, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(() => {
          loadData(motorbike);
          setResponseMessage("Data saved!");
        })
        .catch((error) => {
          setResponseMessage(error);
        });
    } else {
      axios
        .put(process.env.REACT_APP_API_BASE_URL + "/wp-json/wp/v2/fuel-costs/" + id, postData, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(() => {
          loadData(motorbike);
          setIsInsert(true);
          setResponseMessage("Data updated!");
        })
        .catch((error) => {
          setResponseMessage(error);
        });
    }

    // Remove message after some time
    setTimeout(() => {
      setResponseMessage("");
    }, 2000);
  };

  const toggle = () => {
    setIsInsert(!isInsert);
  };

  const dataChanged = () => {
    if (company !== specificData[0].acf.company) {
      return true;
    }
    if (country !== specificData[0].acf.country) {
      return true;
    }
    if (place !== specificData[0].acf.place) {
      return true;
    }
    if (price !== specificData[0].acf.price) {
      return true;
    }
    if (type !== specificData[0].acf.type) {
      return true;
    }
    if (volume !== specificData[0].acf.volume) {
      return true;
    }
    if (!isInsert && mileage !== Number(specificData[0].acf.mileage)) {
      return true;
    }
  };

  return (
    <Container className="bg-success">
      <Row className="py-2">
        <Col>
          <Button variant={isInsert ? "info" : "primary"} onClick={toggle}>
            {isInsert ? "Insert" : "Update"}
          </Button>
        </Col>
        <Col className="text-end">
          <Button variant="info" onClick={destroyToken}>
            Log out
          </Button>
        </Col>
      </Row>
      <Form>
        <Row xs={1} md={3} className="pt-2">
          <Col className="d-flex align-items-center justify-content-center">
            <div>
              <span>
                <b>{insertDate ? moment(insertDate).format("DD/MM/YYYY") : "Date"}</b>
              </span>
              <p>{moment(insertDate, "YYYYMMDD, hh:mm:ss").fromNow()}</p>
            </div>
          </Col>
          <Col>
            <MileageInput mileage={mileage} increase={increase} decrease={decrease} />
          </Col>
          <Col className="d-flex align-items-center justify-content-center">
            <div>
              <span>
                <b>Distance</b>
              </span>
              <p>{mileageSincePurchase + " " + (mileageDifference !== 0 ? (mileageDifference > 0 ? "+" : "") + mileageDifference : "")} km</p>
            </div>
          </Col>
        </Row>
        <InputFieldWrapper>
          <Form.Group className="mb-3">
            <Form.Label>
              <b>Motorbike</b>
            </Form.Label>
            <Form.Select value={motorbike} onChange={(e) => setMotorbike(e.target.value)}>
              <option value="Z400">Z400</option>
              <option value="Versys 650">Versys 650</option>
            </Form.Select>
          </Form.Group>
        </InputFieldWrapper>
        <InputFieldWrapper>
          <Form.Group className="mb-3">
            <Form.Label>
              <b>Company</b>
            </Form.Label>
            <Form.Control type="text" list="company_data" value={company} onChange={(e) => setCompany(e.target.value)} />
            <datalist id="company_data">
              {companyOptions.map((item) => (
                <option key={item} value={item} />
              ))}
            </datalist>
          </Form.Group>
        </InputFieldWrapper>
        <InputFieldWrapper>
          <Form.Group className="mb-3">
            <Form.Label>
              <b>Country</b>
            </Form.Label>
            <Form.Control type="text" list="country_data" value={country} onChange={(e) => setCountry(e.target.value)} />
            <datalist id="country_data">
              {countryOptions.map((item) => (
                <option key={item} value={item} />
              ))}
            </datalist>
          </Form.Group>
        </InputFieldWrapper>
        <InputFieldWrapper>
          <Form.Group className="mb-3">
            <Form.Label>
              <b>Place</b>
            </Form.Label>
            <Form.Control type="text" list="place_data" value={place} onChange={(e) => setPlace(e.target.value)} />
            <datalist id="place_data">
              {placeOptions.map((item) => (
                <option key={item} value={item} />
              ))}
            </datalist>
          </Form.Group>
        </InputFieldWrapper>
        <InputFieldWrapper>
          <Form.Group className="mb-3">
            <Form.Label>
              <b>Price</b>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text id="price">€</InputGroup.Text>
              <Form.Control aria-label="Price" aria-describedby="price" type="number" step="0.01" value={price} onChange={(e) => setPrice(e.target.value)} />
            </InputGroup>
          </Form.Group>
        </InputFieldWrapper>
        <InputFieldWrapper>
          <Form.Group className="mb-3">
            <Form.Label>
              <b>Type</b>
            </Form.Label>
            <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
              <option value="E5">E5</option>
              <option value="E10">E10</option>
            </Form.Select>
          </Form.Group>
        </InputFieldWrapper>
        <InputFieldWrapper>
          <Form.Group className="mb-3">
            <Form.Label>
              <b>Volume</b>
            </Form.Label>
            <InputGroup>
              <Form.Control aria-label="Volume" aria-describedby="volume" type="number" step="0.01" value={volume} onChange={(e) => setVolume(e.target.value)} />
              <InputGroup.Text id="volume">L</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </InputFieldWrapper>
        {(mileageDifference !== 0 || !isInsert) && dataChanged() && (
          <Button variant={isInsert ? "info" : "primary"} className="w-100" onClick={save}>
            {isInsert ? "Save" : "Update"}
          </Button>
        )}
        <Row className="pt-3">
          <Col className="text-center">{responseMessage !== "" ? <h3 className="text-warning">{responseMessage}</h3> : ""}</Col>
        </Row>
        <Row className="py-3">
          <Col>
            <b>Fuel consumption</b>
          </Col>
          <Col>{fuelConsumption} km / L</Col>
          <Col>
            <i>{averageFuelConsumption} km / L</i>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <b>Fuel Price</b>
          </Col>
          <Col>&euro;{(price / volume).toFixed(3)}</Col>
          <Col>
            <i>&euro;{averagePrice}</i>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <b>Expected range</b>
          </Col>
          <Col>
            {Math.round(fuelConsumption * (fuelCapacity - 0.5))} km ({Math.round(fuelConsumption * (fuelCapacity - 0.5) + mileage)})
          </Col>
          <Col>
            <i>
              {Math.round(averageFuelConsumption * (fuelCapacity - 0.5))} km ({Math.round(averageFuelConsumption * (fuelCapacity - 0.5) + mileage)})
            </i>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
