import React from "react";
import "./style.scss";

function Mileage({ value, increase, decrease }) {
  return (
    <div className="mileage-number">
      <button className="mileage-input" onClick={increase}>
        +
      </button>
      <div className="mileage-value">{value}</div>
      <button className="mileage-input" onClick={decrease}>
        -
      </button>
    </div>
  );
}

export default function MilageInput(props) {
  const mileageNumberElements = String(props.mileage)
    .split("")
    .map((item, index) => <Mileage className="input" key={index} value={item} increase={(e) => props.increase(e, index)} decrease={(e) => props.decrease(e, index)} />);

  return <div className="mileage-numbers">{mileageNumberElements}</div>;
}
