import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Offline from "./components/Offline";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Offline>
    <App />
  </Offline>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (e) => {
    const { waiting: { postMessage = null } = {} as any, update } = e || {};
    if (postMessage) {
      postMessage({ type: "SKIP_WAITING" });
    }
    update().then(() => {
      window.location.reload();
    });
  },
});
// https://stackoverflow.com/questions/56972246/how-to-update-reactjs-based-pwa-to-the-new-version

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
